import {
  Component,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import {
  AnimationBuilder,
  Config,
  IonRouterOutlet,
  NavController
} from "@ionic/angular";
import {
  ActivatedRoute,
  UrlTree
} from "@angular/router";
import {
  NavigationService
} from "../../services/navigation.service";
import {
  ActivatedRouteFixService
} from "../../services/activated-route-fix.service";
import {
  BackButtonService
} from "../../services/back-button.service";

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input()
  defaultHref: string | any[] | UrlTree | undefined | null;

  @Input()
  queryParams;

  @Input()
  disableClick: boolean = false;

  @Input()
  routerAnimation?: AnimationBuilder;

  public constructor(
    @Optional() private routerOutlet: IonRouterOutlet,
    private backButtonService: BackButtonService,
  ) {
  }

  public ngOnInit(): void {
  }

  public onClick(ev: Event = null): void {
    if (this.disableClick) {
      return;
    }

    this.backButtonService.goBack(this.defaultHref, this.queryParams);
  }
}
