import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Voip, { Order, Token } from '../plugins/voip/voip.plugin';
import { Client, UserDeviceRequest } from '../api-clients/pyjam/client';
import { PlatformService } from './platform.service';
import { PhoneInfoService } from '../avatar/services/phone-info.service';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VoipNotificationsService {

  constructor(
    private router: Router,
    private client: Client,
    private platformService: PlatformService,
    public phoneInfoService: PhoneInfoService,
  ) {
  }

  async initializeVoipPushNotifications(): Promise<void> {
    if (!this.platformService.isDevice || !this.platformService.isIos) return;

    await this.phoneInfoService.getDeviceModel();

    await Voip.addListener('voipPushRegistry', (async (token: Token): Promise<void> => {
      console.log(`VOIP token has been received ${token}`);

      const body = {
        token: token.token,
        type: 2,
        name: this.phoneInfoService.phoneModelWithOS
      } as UserDeviceRequest;

      await firstValueFrom(this.client.userDevicePost(body));
    }));

    await Voip.addListener('handleOrder', ((order: Order): void => {
      console.log(`Call has been received for ${order.id}`);

      this.router.navigate(['avatar/order-details', order.id]);
    }));

    await Voip.register();
    console.log('VoIP Push notification has been registered');
  }
}