import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { Router, RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localePl from '@angular/common/locales/pl';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { API_BASE_URL, Client, } from './api-clients/pyjam/client';
import { ExtendedClient } from './api-clients/pyjam/extended-client';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { NgEventBus } from 'ng-event-bus';
import { BatteryStatus } from '@awesome-cordova-plugins/battery-status/ngx';
import { StripeModule } from './stripe/stripe.module';
import { NotificationModule } from './notification/notification.module';
import { ChatController } from './chat/chat.controller';
import * as Sentry from '@sentry/angular';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
  TranslateService
} from '@ngx-translate/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { LicenseAgreementComponent } from './docs/license-agreement/license-agreement.component';
import { PrivacyPolicyComponent } from './docs/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './docs/cookies-policy/cookies-policy.component';
import { TermsAndConditionsComponent } from './docs/terms-and-conditions/terms-and-conditions.component';
import { DisclaimerComponent } from './docs/disclaimer/disclaimer.component';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { LoginModule } from './login/login.module';
import { ReplyModule } from './reply/reply.module';
import { PyjamAnimateComponent } from './pyjam-animate/pyjam-animate.component';
import type { IonicConfig } from '@ionic/core';
import { ConsoleErrorComponent } from './console-error/console-error.component';
import { CombinedErrorHandlerService } from './services/error-handlers/combined-error-handler.service';


export function createTranslateLoader(httpHandler: HttpBackend): MultiTranslateHttpLoader {
  // return new TranslateHttpLoader(new HttpClient(httpHandler), './assets/i18n/', '.json');

  const suffix: string = '.json?v=' + Date.now();

  return new MultiTranslateHttpLoader(new HttpClient(httpHandler), [
    {prefix: './assets/i18n/core/', suffix},
    {prefix: './assets/i18n/extra/', suffix}
  ]);
}


export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    console.error('Translation missing for key: ', params.key);
    return params.key;
  }
}


registerLocaleData(localeRu);
registerLocaleData(localePl);

const SentryAndTelegramErrorHandlerProviders: Provider = {
  provide: ErrorHandler,
  useClass: CombinedErrorHandlerService
};

const SentryTraceService: Provider = {
  provide: Sentry.TraceService,
  deps: [Router],
};

const SentryAppInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: () => (): void => {
  },
  deps: [Sentry.TraceService],
  multi: true,
};

const ApiBaseURLProvider: Provider = {
  provide: API_BASE_URL,
  useValue: environment.urlBackEndApi
};

const RouteStrategyProvider: Provider = {
  provide: RouteReuseStrategy,
  useClass: IonicRouteStrategy
};

const LocaleIdProvider: Provider = {
  provide: LOCALE_ID,
  deps: [TranslateService],
  useFactory: (languageService: TranslateService): string => {
    const langs: string[] = ['en-US', 'de-DE', 'fr-FR', 'es-ES', 'it-IT', 'zh-CN', 'ja-JP', 'kk-KZ', 'tr-TR', 'pl-PL'];
    const lang: string = languageService.currentLang || languageService.defaultLang;
    return lang
      ? langs.find((l: string) => lang.startsWith(l))
      : 'en-US';
  }
};

const AuthInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};

const ApiInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (authService: AuthService, chatController: ChatController) => {
    return () => new Promise<void>(async (resolve, reject): Promise<void> => {
      try {
        await authService.init();
        await chatController.init();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  deps: [AuthService, ChatController],
  multi: true
};

const TranslateLoaderProvider: Provider = {
  provide: TranslateLoader,
  useFactory: (createTranslateLoader),
  deps: [HttpBackend]
};


const translateModuleConfig: TranslateModuleConfig = {
  loader: TranslateLoaderProvider,
  missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
  useDefaultLang: true
};

const socketModuleConfig: SocketIoConfig = environment.socketConfig;

const ionicModuleConfig: IonicConfig = {
  swipeBackEnabled: false,
  loadingSpinner: 'crescent',
};

@NgModule({
  declarations: [
    AppComponent,
    LicenseAgreementComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    TermsAndConditionsComponent,
    DisclaimerComponent,
    PyjamAnimateComponent,
    ConsoleErrorComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(ionicModuleConfig),
    SocketIoModule.forRoot(socketModuleConfig),
    AppRoutingModule,
    AuthModule.forRoot(),
    InfiniteScrollModule,
    StripeModule,
    NotificationModule,
    HttpClientModule,
    LoginModule,
    ReplyModule,
    TranslateModule.forRoot(translateModuleConfig),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    UiComponentsModule,
  ],
  providers: [
    SentryAppInitializer,
    SentryTraceService,
    SentryAndTelegramErrorHandlerProviders,
    ApiBaseURLProvider,
    RouteStrategyProvider,
    LocaleIdProvider,
    HTTP,
    Client,
    ExtendedClient,
    AuthInterceptorProvider,
    // NgEventBus,
    ApiInitializerProvider,
    Geolocation,
    BatteryStatus,
    Media,
    File,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

//#region fix of FileReader issue during capacitor build
// https://github.com/ionic-team/capacitor/issues/1564

export class FileReaderA extends window.FileReader {
  constructor() {
    super();
    const zoneOriginalInstance = (this as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || this;
  }
}

window.FileReader = FileReaderA;

//#endregion fix of FileReader issue during capacitor build
