import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ToastService
} from "../../services/toast.service";
import {
  ExtendedClient
} from "../../api-clients/pyjam/extended-client";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import {
  LoadingService
} from "../../services/loading.service";
import {
  TranslateService
} from '@ngx-translate/core';
import {
  NavigationService
} from "../../services/navigation.service";
import {
  Stripe,
  StripeElements,
  StripeElementsOptions,
  StripePaymentElement
} from '@stripe/stripe-js';
import {
  loadStripe
} from '@stripe/stripe-js/pure';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent {
  constructor() {
  }
}

