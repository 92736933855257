import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'linkInText'
})
export class LinkInTextPipe implements PipeTransform {

  transform(text: string, ...args: any[]): any {
    let strArray = text.split(' ');
    let finalStr = '';
    for (let str of strArray) {
      if (str.includes('http', 0) || str.includes('https', 0)) {
        str = '<a href="' + str + '">' + str + '</a>';
      }
      finalStr += str + ' ';
    }

    return finalStr;
  }

}
