import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileResponse} from "../../api-clients/pyjam/client";

@Component({
  selector: 'app-files-set',
  templateUrl: './files-set.component.html',
  styleUrls: ['./files-set.component.scss'],
})
export class FilesSetComponent implements OnInit {
  @ViewChild('imageSlider') imageSlider;

  @Input()
  set files(files: FileResponse[]) {
    this._files = files;
  }

  @Input() profile;

  @Input() acceptEditing = true;

  get files() {
    return this._files;
  }

  private _files: FileResponse[] = [];

  @Input()
  public filesChanged: EventEmitter<any>;

  @Input()
  public allowDeleting: boolean = false;

  @Input()
  public filesDisplayMode: 'slim' | 'full';

  @Output()
  public deleteClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public filesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  private count = 0;
  constructor() {
  }

  ngOnInit() {
    this.filesChanged?.subscribe((change) => {
      // this.startLoopVideo();
      this.imageSlider.showImages([], 0);
    });
  }

  startLoopVideo() {
    let elements = document.getElementsByTagName('video');
    for (let i = 0; i < elements.length; i++) {
      elements[i].play();
      elements[i].muted = true;
    }
  }

  deleteProfileFile(id: number) {
    if (this.allowDeleting) {
      this.deleteClicked.emit(id);
    }
  }

  checkLoad() {
    this.count += 1;
    if (this.count === this.files.length) {
      this.filesLoaded.emit(true);
    }
  }
}
