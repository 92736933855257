import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe, StripeElements, StripeElementsOptions, StripePaymentElement } from '@stripe/stripe-js';
import { ExtendedClient } from '../../api-clients/pyjam/extended-client';
import { ToastService } from '../../services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
// import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group";
// import { loadScript } from "@paypal/paypal-js";
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-input',
  templateUrl: './payment-input.component.html',
  styleUrls: ['./payment-input.component.scss'],
})
export class PaymentInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public paymentIntentType: number;
  @Input()
  public tipAmount: number;

  public cardErrors = null;
  public replyOrExtraWorkId: number;

  public pkCode = null;
  public vm: PaymentVm = new PaymentVm();
  private stripe: Stripe;
  private elements: StripeElements;
  private paymentElement: StripePaymentElement;
  cardFields: any;

  constructor(
    private extendedClient: ExtendedClient,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private translate: TranslateService,
  ) {
  }

  ngOnDestroy() {
    try {
      this.paymentElement.destroy();
    } catch (e) {

    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.id) {
        if (+params.id) {
          this.replyOrExtraWorkId = +params.id;

          this.loadingService.start()
            .then(async () => {
              await this.loadSecretCode();
            })
            .finally(async () => {
              await this.loadingService.stop();
            });

        } else {
          this.router.navigate(['../']).then();
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.vm.gateway) {
      if (this.vm.gateway == 'paypal') {
        this.createPaypalForm();
      }
    } else {
      setTimeout(() => {
        this.ngAfterViewInit(); // Вызываем сами себя после загрузки данных
      }, 1000);
    }
  }

  public async createStripeForm(): Promise<void> {
    this.stripe = await loadStripe(this.pkCode);
    let stripeElementsOptions: StripeElementsOptions = {
      clientSecret: this.vm.clientSecret,
      appearance: {
        theme: 'night',
      },
      locale: 'en'
    };
    this.elements = this.stripe.elements(stripeElementsOptions);
    this.paymentElement = this.elements.create('payment');
    await this.paymentElement.mount('#card-element');
  }

  public async loadSecretCode(): Promise<any> {
    return await this.extendedClient.paymentIntent(this.replyOrExtraWorkId, this.paymentIntentType, this.tipAmount ? this.tipAmount : undefined, undefined, undefined).toPromise()
      .then((data) => {
        console.log(data);

        let payment = new PaymentVm();

        switch (data.gateway) {
          case 'stripe':
            payment.amount = data.amount.toString();
            payment.clientSecret = data.client_secret;
            payment.currency = data.currency;
            payment.gateway = data.gateway;
            this.vm = payment;
            break;
          case 'paypal':
            payment.id = data.id;
            payment.gateway = data.gateway;
            payment.amount = data.amount.toString();
            payment.currency = data.currency;
            this.vm = payment;
            break;
          default:
            this.toastService.error(this.translate.instant('reply.payment.undefinedGateway'));
            break;
        }
      })
      .catch(async (err) => {
        let parsedException = JSON.parse(err.response);
        let message = parsedException.message ?? parsedException.error;
        await this.toastService.error(message);
      });
  }

  public async sendCardToken(): Promise<void> {
    this.loadingService.start()
      .then(async () => {
        switch (this.vm.gateway) {
          case 'stripe':
            await this.stripe.confirmPayment({
              elements: this.elements,
              redirect: 'if_required'
            }).then(async (result) => {
              if (result.error) {
                this.cardErrors = result.error.message;
              } else {
                if (this.paymentIntentType == 1) {
                  await this.approveReply();
                }
                if (this.paymentIntentType == 2) {
                }
                if (this.paymentIntentType == 4) {
                  await this.extendedClient.replyGet(this.replyOrExtraWorkId, false).toPromise()
                    .then(async (data) => {
                      await this.navigationService.goBack();
                    })
                    .catch(async (err) => {
                      await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
                    });
                }
              }
            });
            break;
          case 'paypal':
            this.loadingService.start();

            this.cardFields.submit().then(() => {
              console.log('Card Fields submit');
            }).catch((err) => {
              this.cardErrors = err;

              console.log('There was an error with card fields: ', err);
            }).finally(async () => {
              await this.loadingService.stop();
            });
            break;
          default:
            await this.toastService.error(this.translate.instant('reply.payment.undefinedGateway'));
            break;
        }
      })
      .finally(async () => {
        await this.loadingService.stop();
      });
  }

  public async approveReply(): Promise<void> {
    return this.extendedClient.replyApprove(+this.replyOrExtraWorkId).toPromise()
      .then(async () => {
        await this.toastService.success(this.translate.instant('reply.payment.successPay'));
        await this.navigationService.goBack();
      })
      .catch(async (err) => {
        let parsedException = JSON.parse(err.response);
        let message = parsedException.message ?? parsedException.error;
        await this.toastService.error(message);
        await this.router.navigate(['../payment-result', 'fail'], {
          relativeTo: this.activatedRoute,
          replaceUrl: true
        });
      });
  }

  async selectPaymentMethod($event: any) {
    this.pkCode = $event.detail.value;
    this.createStripeForm();
  }

  createPaypalForm() {
    const style = {
      'input': {
        'font-size': '16px',
        'padding': '12px',
        'line-height': '18px',
      },
    };

    //@ts-ignore
    this.cardFields = paypal.CardFields({
      style,
      createOrder: () => {
        return new Promise((resolve) => resolve(this.vm.id));
      },
      onApprove: (data) => {
        switch (this.paymentIntentType) {
          case 1:
            return this.extendedClient.paypalAuthorizeOrder(data.orderID).toPromise()
              .then(async (data) => {
                console.log(data);

                await this.approveReply();
              })
              .catch(async (err) => {
                this.cardErrors = err.result.message;
              });
          case 2:
          case 3:
            return this.extendedClient.paypalAuthorizeOrder(data.orderID).toPromise()
              .then(async (data) => {
                console.log(data);
              })
              .catch(async (err) => {
                this.cardErrors = err.result.message;
              });
          case 4:
            return this.extendedClient.paypalCaptureOrder(data.orderID).toPromise()
              .then(async (data) => {
                console.log(data);

                await this.extendedClient.replyGet(this.replyOrExtraWorkId, false).toPromise()
                  .then(async (data) => {
                    await this.navigationService.goBack();
                  })
                  .catch(async (err) => {
                    await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
                  });
              })
              .catch(async (err) => {
                this.cardErrors = err.result.message;
              });
          case 5:
            return this.extendedClient.paypalCaptureOrder(data.orderID).toPromise()
              .then(async (data) => {
                console.log(data);
                // TODO: make logic for avatar tips
              })
              .catch(async (err) => {
                this.cardErrors = err.result.message;
              });

          default:
            break;
        }
      },
      onError() {
        this.cardErrors = 'The requested action could not be performed, semantically incorrect, or failed business validation';
      }
    });

    // Render each field after checking for eligibility
    if (this.cardFields.isEligible()) {
      const nameField = this.cardFields.NameField();
      nameField.render('#card-name-field-container');

      const numberField = this.cardFields.NumberField();
      numberField.render('#card-number-field-container');

      const cvvField = this.cardFields.CVVField();
      cvvField.render('#card-cvv-field-container');

      const expiryField = this.cardFields.ExpiryField();
      expiryField.render('#card-expiry-field-container');
    }

    // const multiCardFieldButton = document.getElementById("card-field-submit-button")

    // multiCardFieldButton.addEventListener("click", () => {
    //   console.log("multiCardFieldButton clicked");

    //   cardFields.submit().then(() => {
    //     console.log("Card Fields submit");
    //   }).catch((err) => {
    //     console.log("There was an error with card fields: ", err);
    //   });
    // });

    // this.cardFields = cardFields;

    // console.log(cardFields);

    // console.log(loadScript);

    // loadScript({
    //   clientId: environment.paypal.clientId,
    //   components: ['buttons']
    // }).then((paypal) => {

    //     paypal
    //       .Buttons({
    //         onApprove: (data, actions) => new Promise(() => {
    //           console.log(data, actions);
    //         }),
    //         createOrder: () => new Promise(() => {
    //           console.log(this.vm.id);
    //           return this.vm.id;
    //         }),
    //         onError(err) {
    //           console.log(err);
    //         },
    //       })
    //       .render("#paypal-button-container")
    //       .catch((error) => {
    //         console.error("failed to render the PayPal Buttons", error);
    //       });

    //     // if (false) {
    //       paypal.HostedFields.render({
    //         createOrder: () => new Promise(() => this.vm.id),
    //         // function () {
    //         //   return fetch('/your-server/paypal/order', {
    //         //     method: 'post'
    //         //   }).then(function(res) {
    //         //     return res.json();
    //         //   }).then(function(orderData) {
    //         //     orderId = orderData.id;
    //         //     return orderId;
    //         //   });
    //         // },
    //         styles: {
    //           '.valid': {
    //             'color': 'green'
    //           },
    //           '.invalid': {
    //             'color': 'red'
    //           }
    //         },
    //         fields: {
    //           number: {
    //             selector: "#card-number-field-container",
    //             placeholder: "4111 1111 1111 1111"
    //           },
    //           cvv: {
    //             selector: "#card-cvv-field-container",
    //             placeholder: "123"
    //           },
    //           expirationDate: {
    //             selector: "#card-expiry-field-container",
    //             placeholder: "MM/YY"
    //           }
    //         }
    //       }).then(function (cardFields) {

    //         console.log(cardFields);


    //         // document.querySelector("#card-form").addEventListener('submit', (event) => {
    //         //   event.preventDefault();
    //         //   cardFields.submit({
    //         //     cardholderName: document.getElementById('card-holder-name').value,
    //         //     billingAddress: {
    //         //       streetAddress: document.getElementById('card-billing-address-street').value,
    //         //       extendedAddress: document.getElementById('card-billing-address-unit').value,
    //         //       region: document.getElementById('card-billing-address-state').value,
    //         //       locality: document.getElementById('card-billing-address-city').value,
    //         //       postalCode: document.getElementById('card-billing-address-zip').value,
    //         //       countryCodeAlpha2: document.getElementById('card-billing-address-country').value
    //         //     }
    //         //   }).then(function () {
    //         //     fetch('/your-server/api/order/' + orderId + '/capture/', {
    //         //       method: 'post'
    //         //     }).then(function(res) {
    //         //       return res.json();
    //         //     }).then(function (orderData) {
    //         //       var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
    //         //       if (errorDetail) {
    //         //         var msg = 'Sorry, your transaction could not be processed.';
    //         //         if (errorDetail.description) msg += '\n' + errorDetail.description;
    //         //         if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
    //         //         return alert(msg); // Show a failure message
    //         //       }
    //         //       alert('Transaction completed!');
    //         //     })
    //         //   }).catch(function (err) {
    //         //     alert('Payment could not be captured! ' + JSON.stringify(err))
    //         //   });
    //         // });
    //       });
    //     // } else {
    //       // @ts-ignore
    //       // document.querySelector("#card-form").style = 'display: none';
    //     // }
    // })
    // .catch((error) => {
    //   console.error("failed to load the PayPal JS SDK script", error);
    // });
  }
}

class PaymentVm {
  clientSecret: string;
  amount: string;
  currency: string;
  id: string;
  gateway: string;
}
