import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineStateService {
  private onlineEvent: Observable<Event>;
  private offlineEvent: Observable<Event>;
  public isOfflineSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOfflinePopupShow: boolean = false;
  // private durationPopup: number = 5000;

  constructor() {
    this.subscribeToEvents();
  }

  get isOffline(): boolean {
    return this.isOfflineSubject.value;
  }

  private subscribeToEvents(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.onlineEvent.subscribe((): void => {
      console.log('\x1b[32m' + 'You are online' + '\x1b[0m');
      this.isOfflinePopupShow = false;
      this.isOfflineSubject.next(false);
    });

    this.offlineEvent = fromEvent(window, 'offline');
    this.offlineEvent.subscribe((): void => {
      console.log('\x1b[31m' + 'You are offline' + '\x1b[0m');
      this.isOfflinePopupShow = true;
      this.isOfflineSubject.next(true);

      // setTimeout((): void => {
      //   this.isOfflinePopupShow = false;
      // }, this.durationPopup);
    });
  }
}
