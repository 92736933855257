import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanActivate, CanLoad {

  constructor(
    private authService: AuthService,
    private navController: NavController,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isAuthenticated) {
      window.localStorage.setItem('introPassed', '1');
      this.navController.navigateForward(['avatar'], {replaceUrl: true}).then((): boolean => false);
    } else {
      return true;
    }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isAuthenticated) {
      window.localStorage.setItem('introPassed', '1');
      this.navController.navigateForward(['avatar'], {replaceUrl: true}).then((): boolean => false);
    } else {
      return true;
    }
  }
}
