import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() public name: string = '';
  @Input() public avatarFile: any;
  @Input() public typeAvatar: 'default' | 'with-light' = 'default';
  @Input() public sizeLight: 'big' | 'medium' | 'small' | 'none' | 'super-big' = 'small';
  @Input() public showOriginalByClick: boolean = true;

  // get File(): any {
  //   return {
  //     resizes: this.avatarFile.resizes,
  //     type: {id: 3, name: 'image'},
  //     name: this.name,
  //     origin: this.avatarFile.origin
  //   };
  // }

  // showImage(imageSlider, $event) {
  //   if(this.showOriginalByClick) {
  //     $event.stopImmediatePropagation();
  //     imageSlider.showImages([this.File], 0);
  //   }
  // }
}
