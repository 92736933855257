import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { Client, ReplyResponse } from '../../api-clients/pyjam/client';
import { ToastService } from '../../services/toast.service';
import { AuthService } from '../../auth/auth.service';
import { ReplyStatus } from '../reply.constants';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRouteFixService } from '../../services/activated-route-fix.service';
import { EventService } from '../../services/events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reply-info',
  templateUrl: './reply-info.component.html',
  styleUrls: ['./reply-info.component.scss'],
})
export class ReplyInfoComponent implements OnInit {

  public reply: ReplyResponse;

  constructor(
    private activatedRoute: ActivatedRoute,
    private activatedRouteFixService: ActivatedRouteFixService,
    private loadingService: LoadingService,
    private client: Client,
    private toastService: ToastService,
    private router: Router,
    public authService: AuthService,
    private navigationService: NavigationService,
    private eventBus: EventService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params?.id) {
      this.loadingService.start()
        .then(async () => {
          await this.loadReplyInfo(this.activatedRoute.snapshot.params?.id);
        })
        .finally(async () => {
          await this.loadingService.stop();
        });
    }
  }

  public get ReplyStatus(): typeof ReplyStatus {
    return ReplyStatus;
  }

  public async loadReplyInfo(id: number): Promise<void> {
    await this.client.replyGet(id, false).toPromise()
      .then((data) => {
        this.reply = data.data;
      })
      .catch(async ex => {
        this.translate.get('reply.replyInfo.responseWasRemoved').subscribe((t) => {
          this.toastService.warning(t);
        });
        await this.navigationService.goBack();
      });
  }

  async onReplyApproveClick() {
    await this.router.navigate(['/reply/' + this.reply.id + '/payment']);
  }

  async onReplyRejectClick() {
    this.client.replyReject(this.reply.id).toPromise()
      .then(() => {
        this.loadReplyInfo(this.reply.id).then();
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });
  }

  async onReplyEditClick() {
    await this.router.navigate(['edit'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  async onReplyDeleteClick() {
    this.client.replyDelete(this.reply.id).toPromise()
      .then(() => {
        this.eventBus.publish('task-detail:updated');
        this.navigationService.goBack();
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });
  }

  async onReplySendResultClick() {
    await this.router.navigate(['task-result/files'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  async onShowTaskResultClick() {
    await this.router.navigate(['show-result'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  async onGoToChatClick() {
    await this.router.navigate(['chats', this.reply.chat_id]);
  }

  async onShowBalanceClick() {
    await this.router.navigate(['/profile/balance']);
  }

  getParams() {
    if (this.reply.user_id == this.authService.parsedToken.userId) {
      return {
        percent: this.reply.dispute_resolve_result.contractor_percent,
        amount: this.reply.dispute_resolve_result.contractor_amount
      };
    }
    return {
      percent: this.reply.dispute_resolve_result.owner_percent,
      amount: this.reply.dispute_resolve_result.owner_amount
    };
  }
}
