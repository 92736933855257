import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {StripeRefreshUrlComponent} from "./stripe-refresh-url/stripe-refresh-url.component";
import {StripeReturnUrlComponent} from "./stripe-return-url/stripe-return-url.component";
import {StripeRoutingModule} from "./stripe-routing.module";
import {
  TranslateModule
} from "@ngx-translate/core";

@NgModule({
  declarations: [
    StripeRefreshUrlComponent,
    StripeReturnUrlComponent
  ],
  imports: [
    CommonModule,
    StripeRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TranslateModule,
  ]
})
export class StripeModule {
}
