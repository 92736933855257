import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { PluginListenerHandle } from '@capacitor/core';
// import { NodeEventHandler } from 'rxjs/internal/observable/fromEvent';

@Injectable({
  providedIn: 'root'
})
export class AppStateChangeService implements OnDestroy {
  private appStateChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private resumeSubject: BehaviorSubject<true> = new BehaviorSubject(null);
  private platformResumeSubscription: Subscription;
  private appStateChangeListener: PluginListenerHandle;
  // private appStateChangeSubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private platform: Platform,
  ) {
    this.subscribeToAppEvents().then();
  }

  async ngOnDestroy(): Promise<void> {
    this.resumeSubject.complete();
    this.appStateChangeSubject.complete();

    this.platformResumeSubscription.unsubscribe();
    await this.appStateChangeListener.remove();
    // this.appStateChangeSubscription.unsubscribe();
  }

  private async subscribeToAppEvents(): Promise<void> {
    // Срабатывает только при возобновлении приложения из фона.
    // Специфичен для платформы Cordova.
    this.platformResumeSubscription = this.platform.resume.subscribe((): void => {
      this.emitResume(true);
    });

    // Работает с любыми изменениями состояния приложения (активное или неактивное)
    // Является более универсальным и поддерживает платформы iOS и Android через Capacitor.
    // Variant 1 - addListener
    this.appStateChangeListener = await App.addListener('appStateChange', async ({isActive}): Promise<void> => {
      await this.ngZone.run(async (): Promise<void> => {
        this.emitAppState(isActive);
      });
    });

    // Variant 2 - Observable
    // const appStateChangeEvent: Observable<{ isActive: boolean }> = fromEventPattern(
    //   async (handler: NodeEventHandler): Promise<PluginListenerHandle> => await App.addListener('appStateChange', handler),
    //   async (): Promise<void> => await App.removeAllListeners()
    // );
    //
    // this.appStateChangeSubscription = appStateChangeEvent.subscribe(async ({isActive}): Promise<void> => {
    //   await this.ngZone.run(async (): Promise<void> => {
    //     this.emitAppState(isActive);
    //   });
    // });
  }

  public get resume$(): Observable<boolean> {
    return this.resumeSubject.asObservable();
  }

  public get appStateChange$(): Observable<boolean> {
    return this.appStateChangeSubject.asObservable();
  }

  public emitResume(isActive: true): void {
    // console.log('\x1b[95m' + 'App resume is: ' + isActive + '\x1b[0m');
    this.resumeSubject.next(isActive);
  }

  public emitAppState(isActive: boolean): void {
    // console.log('\x1b[95m' + 'App state is active: ' + isActive + '\x1b[0m');
    this.appStateChangeSubject.next(isActive);
  }
}
