import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformService } from '../services/platform.service';

@Injectable({
  providedIn: 'root'
})

export class MobileAppGuard implements CanActivate {

  constructor(
    private platformService: PlatformService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.platformService.isDevice) {
      return true;
    } else {
      return this.router.navigateByUrl('/avatar').then((): boolean => false);
    }
  }
}
