import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonDatetime, ModalController} from "@ionic/angular";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-input-sum-modal',
  templateUrl: './input-sum-modal.component.html',
  styleUrls: ['./input-sum-modal.component.scss'],
})
export class InputSumModalComponent implements OnInit {

  @Input()
  public min = 1;

  @Input()
  public max = 99999;

  public form: FormGroup;

  constructor(private modalController: ModalController) {
  }

  public ngOnInit(): void {
    this.form = new FormGroup({
      price: new FormControl('', [
        Validators.required,
        Validators.min(this.min),
        Validators.max(this.max)
      ]),
    });
  }

  public async onSubmitClick(): Promise<void> {
    await this.modalController.dismiss(this.form.controls.price.value);
  }

  public async onCancelClick(): Promise<void> {
    await this.modalController.dismiss();
  }

  onInputKeyDown($event: KeyboardEvent) {
    if ($event.key == "ArrowRight" || $event.key == "ArrowDown"
      || $event.key == "ArrowLeft" || $event.key == "ArrowUp"
      || $event.key == "Delete" || $event.key == "Backspace"
      || $event.key == "0" || $event.key == "1"
      || $event.key == "2" || $event.key == "3"
      || $event.key == "4" || $event.key == "5"
      || $event.key == "6" || $event.key == "7"
      || $event.key == "8" || $event.key == "9") {
      if (($event.key == "0" || $event.key == "1"
          || $event.key == "2" || $event.key == "3"
          || $event.key == "4" || $event.key == "5"
          || $event.key == "6" || $event.key == "7"
          || $event.key == "8" || $event.key == "9")
        && this.form.controls?.price?.value?.toString()?.length >= 6) {
        $event.preventDefault();
      }
    } else {
      $event.preventDefault();
    }
  }
}
