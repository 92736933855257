import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../notification.service';
import { AuthService } from '../../auth/auth.service';
import { PhoneInfoService } from '../../avatar/services/phone-info.service';
import { TelegramService } from '../telegram.service';
import { StoreErrorHandlerService } from './store-error-handler.service';
import { App, AppInfo } from '@capacitor/app';
import { PlatformService } from '../platform.service';
import { ChatController } from '../../chat/chat.controller';

interface ErrorMassage {
  timestamp: string,
  userIP?: string,
  appVersion: string,
  userID: number | string,
  device: string,
  deviceID: number | string,
  socketId: string,
  socketConnected: boolean | string,
  message: string,
  stackStack?: string,
}

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private phoneInfoService: PhoneInfoService,
    private telegramService: TelegramService,
    private storeErrorHandlerService: StoreErrorHandlerService,
    private platformService: PlatformService,
    private chatController: ChatController,
  ) {
  }

  async handleError(error: any): Promise<void> {
    // console.error(error);

    this.storeErrorHandlerService.addError(error);

    const payload: ErrorMassage = await this.createMessage(error);

    await this.telegramService.sendMessage(payload);
  }

  async createMessage(error: any): Promise<ErrorMassage> {
    const timestamp: string = new Date().toISOString();
    // const publicIP: any = await firstValueFrom(this.httpClient.get('https://api.ipify.org?format=json'));
    // const userIP: string = publicIP.ip;
    const appVersion: string = 'Web app';
    const userID: number | string = this.authService.parsedToken.userId || 'User does not authorized';
    const device: string = await this.phoneInfoService.getDeviceModel() || 'Device model is unavailable';
    const deviceID: number | string = this.notificationService.getUserDeviceId() || 'Has not been retrieved from the backend';
    const socketId: string = this.chatController.socketId || 'Socket ID is unavailable';
    const socketConnected: boolean | string = this.chatController.socketConnected || 'Socket connection status is unavailable';
    const message: string = error.message || (typeof error.toString() == 'function' ?? error.toString()) || 'The error message is unavailable';
    // const stackStack: string = error.stack || 'The call stack is unavailable';

    await this.checkChunkLoadError(message);

    const newMessage: ErrorMassage = {
      timestamp,
      // userIP,
      appVersion,
      userID,
      device,
      deviceID,
      socketId,
      socketConnected,
      message,
      // stackStack,
    };

    if (this.platformService.isDevice) {
      const appInfo: AppInfo = await App.getInfo();
      newMessage.appVersion = 'App: version: ' + appInfo.version + ' (build: ' + appInfo.build + ')';
    } else {
      // const data = require('../../../../package.json');
      // newMessage.appVersion = 'Web app version:', data.version;
    }

    return newMessage;
  }

  async checkChunkLoadError(message: string): Promise<void> {
    if (message && message.includes('ChunkLoadError')) {
      console.error('Chunk loading error, refreshing the app.', message);
      await this.router.navigate(['/']);
    }
  }
}

