export enum ReportTypes {
  TASK = 1,
  COMMENT,
  MESSAGE,
  OFFER,
  REPLY,
  EXTRA_WORK,
  TASK_RESULT,
  USER,
  RATING,
}

export enum ReportType {
  TASK = 1,
  TASK_COMMENT,
  CHAT_MESSAGE,
  TASK_RESULT = 7,
  USER_PROFILE,
  RATE,
  FILE
}