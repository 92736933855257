import { Component, OnInit } from '@angular/core';
import {ModalComponentBase} from "../../services/modal-window";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'dispute-confirmation-popup',
  templateUrl: './dispute-confirmation-popup.component.html',
  styleUrls: ['./dispute-confirmation-popup.component.scss'],
})
export class DisputeConfirmationPopupComponent extends ModalComponentBase implements OnInit {

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  ngOnInit() {}

  onCloseClick() {
    super.dismiss({isConfirmed: false}).then();
  }

  onOkClick() {
    super.dismiss({isConfirmed: true}).then();
  }
}
