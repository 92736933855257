import {
  Client,
  TaskResultDataResponse,
  TaskResultRequest,
  TaskResultUpdateRequest,
} from '../../api-clients/pyjam/client';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ReplyStatus } from '../reply.constants';
import { AddingFile } from '../../ui-components/image-adding/image-adding.component';

@Injectable({
  providedIn: 'root'
})

export class TaskResultController {
  public taskResultVm: TaskResultVm;

  constructor(
    private activatedRoute: ActivatedRoute,
    private client: Client,
    private router: Router
  ) {
  }

  ionViewWillEnter() {
  }

  private _initialisationPromise: Promise<void>;

  public get initialisationPromise(): Promise<void> {
    if (!this._initialisationPromise) {
      let replyId = this.activatedRoute.snapshot.params?.id;
      replyId = replyId ? replyId : +this.router.url.split('/')[2];
      this._initialisationPromise = this.client.replyGet(replyId, false).toPromise()
        .then(async (res) => {
          this.taskResultVm = {
            reply_id: res.data.id,
            task_id: res.data.task_id,
            description: '',
            files: [],
          } as TaskResultVm;
          if (res.data.status == ReplyStatus.EXTRA_WORK) {
            await this.client.taskResultIndex(res.data.task_id).toPromise()
              .then(async (rspns) => {
                this.taskResultVm.description = rspns.data[rspns.data.length - 1].description;
              });
          }
        })
        .catch(async ex => {
          // await this.httpErrorHandlerService.handleHttpException(ex);
        });
    }
    return this._initialisationPromise;
  }

  public pushFiles(files: AddingFile[]) {
    this.taskResultVm.files = files;
  }

  public pushText(text: string) {
    this.taskResultVm.text = text;
  }

  public pushDescription(text: string) {
    this.taskResultVm.description = text;
  }

  public async sendTaskResult(): Promise<TaskResultDataResponse> {
    return await this.client.taskResultPost(this.taskResultVm.task_id, this.taskResultVm.reply_id, this.taskResultVm.description, []).toPromise();
  }

  public async editTaskResult(): Promise<TaskResultDataResponse> {
    return await this.client.taskResultUpdateAsJson(this.taskResultVm.task_id, this.taskResultVm.toTaskResultUpdateRequest()).toPromise();
  }

  private taskResultRequestFromTaskResultVm(vm: TaskResultVm): TaskResultRequest {
    return {
      task_id: vm.task_id,
      reply_id: vm.reply_id,
      description: vm.description,
      text: vm.text,
      /*files: vm.files.map(addingFile => {
        return {
          file: addingFile.base64,
          name: addingFile.name,
          size: addingFile.size,
        } as TaskResultFileRequest
      })*/
    } as TaskResultRequest;
  }
}

export class TaskResultVm {
  task_id: number;
  reply_id: number;
  text: string;
  description: string;
  files: AddingFile[];

  public toTaskResultUpdateRequest(): TaskResultUpdateRequest {
    return {
      description: this.description,
      text: this.text,
      /*files: this.files.map(addingFile => {
        return {
          file: addingFile.base64,
          name: addingFile.name,
          size: addingFile.size,
        } as TaskResultFileRequest
      })*/
    } as TaskResultUpdateRequest;
  }
}
