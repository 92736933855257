export enum ReplyStatus {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  COMPLITED_BY_WORKER = 4,
  ACCEPTED_BY_OWNER = 5,
  EXTRA_WORK = 6,
  DISPUTED = 7,
  DISPUTE_CLOSED_IN_FAVOR_OWNER = 8,
  DISPUTE_CLOSED_IN_FAVOR_WORKER = 9,
  DELETED = 10,
  REFRESH = 11,
  EXTRA_WORK_WAIT_PAY = 12,
  EXTRA_WORK_CREATED = 13,
  EXTRA_WORK_REJECTED = 14,
  DISPUTE_SOLVED = 15
}
