import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iconForFile'
})
export class IconForFilePipe implements PipeTransform {

  transform(fileName: string, ...args: any[]): any {
    fileName = fileName.toLowerCase();
    let str = fileName.split('.');
    switch (str[str.length - 1]) {
      case 'ai': return 'assets/icon/files/icon-ai.svg';
      case 'mp3': return 'assets/icon/files/icon-audio.svg';
      case 'wav': return 'assets/icon/files/icon-audio.svg';
      case 'aac': return 'assets/icon/files/icon-audio.svg';
      case 'flac': return 'assets/icon/files/icon-audio.svg';
      case 'alac': return 'assets/icon/files/icon-audio.svg';
      case 'dsd': return 'assets/icon/files/icon-audio.svg';
      case 'pdf': return 'assets/icon/files/icon-pdf.svg';
      case 'doc': return 'assets/icon/files/icon-doc.svg';
      case 'docx': return 'assets/icon/files/icon-doc.svg';
      case 'rtf': return 'assets/icon/files/icon-doc.svg';
      case 'txt': return 'assets/icon/files/icon-doc.svg';
      case 'psd': return 'assets/icon/files/icon-psd.svg';
      case 'ppt': return 'assets/icon/files/icon-ppt.svg';
      case 'pptx': return 'assets/icon/files/icon-ppt.svg';
      case 'avi': return 'assets/icon/files/icon-video.svg';
      case 'mp4': return 'assets/icon/files/icon-video.svg';
      case 'mov': return 'assets/icon/files/icon-video.svg';
      case 'xls': return 'assets/icon/files/icon-xls.svg';
      case 'xlsx': return 'assets/icon/files/icon-xls.svg';
      case 'csv': return 'assets/icon/files/icon-xls.svg';
      case 'xml': return 'assets/icon/files/icon-xls.svg';
      case 'json': return 'assets/icon/files/icon-xls.svg';
      case '7z': return 'assets/icon/files/icon-archive.svg';
      case 'rar': return 'assets/icon/files/icon-archive.svg';
      case 'zip': return 'assets/icon/files/icon-archive.svg';
      default: return 'assets/icon/files/icon-unknown.svg';
    }
  }

}
