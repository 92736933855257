import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Client, RatingRequest } from '../../api-clients/pyjam/client';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'rate-result',
  templateUrl: './rate-result.component.html',
  styleUrls: ['./rate-result.component.scss'],
})
export class RateResultComponent implements OnInit {
  public replyId: number;
  public categoryId: number;
  public form: FormGroup;
  public ratedUserId: number;
  public isPaymentNeeded = false;
  public currentUserId: number;
  public currentUserIdIsPerformer = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private client: Client,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.currentUserId = this.authService.parsedToken.userId;
    this.form = new FormGroup({
      stars: new FormControl('', [
        Validators.min(1)
      ]),
      tip: new FormControl(0, []),
      description: new FormControl(''),
    });
  }

  ionViewWillEnter() {
    this.loadingService.start()
      .then(() => {
        this.replyId = this.activatedRoute.snapshot.params?.id;
        this.client.replyGet(this.replyId, true).toPromise()
          .then(async (res) => {

            if (this.currentUserId !== res.data.user.id) {
              this.ratedUserId = res.data.user.id;
              this.currentUserIdIsPerformer = false;
            }

            await this.client.taskGet(res.data.task_id).toPromise()
              .then(x => {
                this.categoryId = x.data.category.id;
                if (this.currentUserId !== x.data?.owner?.id) {
                  this.currentUserIdIsPerformer = true;
                  this.ratedUserId = x.data?.owner?.id;
                }
              });
          })
          .catch(async ex => {
            // await this.httpErrorHandlerService.handleHttpException(ex);
          })
          .finally(() => {
            this.loadingService.stop().then();
          });
      });
  }

  async onSkipClick() {
    if (this.isPaymentNeeded) {
      this.isPaymentNeeded = false;
    } else {
      await this.navigationService.goBack();
    }
  }

  async onRateClick() {
    if (this.form.controls.stars.value > 0) {
      this.client.ratingPost({
        rating: this.form.controls.stars.value,
        description: this.form.controls.description.value,
        user_id: this.ratedUserId,
        category_id: this.categoryId
      } as RatingRequest).toPromise()
        .then(async () => {
          if (this.form.controls.tip.value === 0) {
            await this.navigationService.goBack();
          }
        })
        .catch(async ex => {
          // await this.httpErrorHandlerService.handleHttpException(ex);
        });
    }

    if (this.form.controls.tip.value > 0) {
      this.isPaymentNeeded = true;
    }
  }

  getTitle(): string {
    return this.isPaymentNeeded
      ? this.translate.instant('reply.payment.title')
      : this.translate.instant('reply.rateResult.review');
  }
}
