import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
