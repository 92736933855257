import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sizeFile'
})
export class SizeFilePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    let fsizekb = value / 1024;
    let fsizemb = fsizekb / 1024;
    let fsizegb = fsizemb / 1024;
    let fsizetb = fsizegb / 1024;
    let fsize = '';

    if(value < 1024) {
      fsize = value + ' byte';
    }
    else if (fsizekb <= 1024 && value >= 1024) {
      fsize = fsizekb.toFixed(0) + ' KB';
    } else if (fsizekb >= 1024 && fsizemb <= 1024) {
      fsize = fsizemb.toFixed(0) + ' MB';
    } else if (fsizemb >= 1024 && fsizegb <= 1024) {
      fsize = fsizegb.toFixed(0) + ' GB';
    } else {
      fsize = fsizetb.toFixed(0) + ' TB';
    }
    return fsize;
  }

}
