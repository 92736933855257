import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FileResponse
} from "../../api-clients/pyjam/client";

@Component({
  selector: 'app-images-set',
  templateUrl: './images-set.component.html',
  styleUrls: ['./images-set.component.scss'],
})
export class ImagesSetComponent implements OnInit {
  @Input()
  public images: FileResponse[] = [];

  @Input()
  public title: string;

  @Input()
  public allowDeleting: boolean = false;

  @Input()
  public allowAdding: boolean = false;

  @Input()
  public wrap: boolean = false;

  @Output()
  public deleteClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public addClicked: EventEmitter<string> = new EventEmitter<string>();

  public constructor() {
  }

  public ngOnInit(): void {
  }
}
