import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ReplyInfoComponent
} from "./reply-info/reply-info.component";
import {
  IonicModule
} from "@ionic/angular";
import {
  ReplyStatusPipe
} from "./reply-status.pipe";
import {
  ReplyRoutingModule
} from "./reply-routing.module";
import {
  UiComponentsModule
} from "../ui-components/ui-components.module";
import {
  EditReplyComponent
} from "./edit-reply/edit-reply.component";
import {
  ReactiveFormsModule
} from "@angular/forms";
import {
  TaskResultComponent
} from "./task-result/task-result.component";
import {
  AddTaskResultComponent
} from "./task-result/add-task-result/add-task-result.component";
import {
  ShowTaskResultComponent
} from "./task-result/show-task-result/show-task-result.component";
import {PaymentComponent} from "./payment/payment.component";
import {
  RateResultComponent
} from "./rate-result/rate-result.component";
import {
  ExtraWorkDescriptionComponent
} from "./extra-work-description/extra-work-description.component";
import {
  PaymentResultComponent
} from "./payment-result/payment-result.component";
import {
  DirectivesModule
} from "../directives/directives.module";
import {TranslateModule} from '@ngx-translate/core';
import {
  DisputeDescriptionComponent
} from "./dispute-description/dispute-description.component";
import {EditTaskResultComponent} from "./task-result/edit-task-result/edit-task-result.component";

@NgModule({
  declarations: [
    ReplyInfoComponent,
    ReplyStatusPipe,
    EditReplyComponent,
    TaskResultComponent,
    AddTaskResultComponent,
    ShowTaskResultComponent,
    PaymentComponent,
    PaymentResultComponent,
    RateResultComponent,
    ExtraWorkDescriptionComponent,
    DisputeDescriptionComponent,
    EditTaskResultComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    IonicModule,
    ReplyRoutingModule,
    UiComponentsModule,
    ReactiveFormsModule,
    DirectivesModule,
    TranslateModule,
  ]
})
export class ReplyModule {
}
