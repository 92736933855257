import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import DeviceProtectedSharedPreferences from '../plugins/deviceProtectedSharedPreferences/deviceProtectedSharedPreferences.plugin';
import { PlatformService } from '../services/platform.service';

@Injectable()
export class TokenStoreService {
  private readonly KEY: string = 'token';
  private readonly KEY2: string = 'refresh_token';
  private readonly secured: boolean;
  constructor(
    private platformService: PlatformService
  ) {
    this.secured = this.platformService.isDevice && this.platformService.isAndroid
  }

  public async getToken(): Promise<string | null> {
    const tokenResult: GetResult = this.secured ?
      await DeviceProtectedSharedPreferences.get({key: this.KEY}) :
      await Preferences.get({key: this.KEY});
    return tokenResult.value ?? null;
  }

  public async getRefreshToken(): Promise<string | null> {
    const refreshTokenResult: GetResult = this.secured ?
      await DeviceProtectedSharedPreferences.get({key: this.KEY2}) :
      await Preferences.get({key: this.KEY2});
    return refreshTokenResult.value ?? null;
  }

  public async setTokens(token: string, refreshToken: string): Promise<void> {

    this.secured ?
      await DeviceProtectedSharedPreferences.set({key: this.KEY, value: token}) :
      await Preferences.set({key: this.KEY, value: token});
    this.secured ?
      await DeviceProtectedSharedPreferences.set({key: this.KEY2, value: refreshToken}) :
      await Preferences.set({key: this.KEY2, value: refreshToken});
  }

  public async removeTokens(): Promise<void> {
    this.secured ?
      await DeviceProtectedSharedPreferences.remove({key: this.KEY}) :
      await Preferences.remove({key: this.KEY});
    this.secured ?
      await DeviceProtectedSharedPreferences.remove({key: this.KEY2}) :
      await Preferences.remove({key: this.KEY2});
  }
}
