import { PluginListenerHandle, registerPlugin } from '@capacitor/core';

export interface CallKitPlugin {
  fulfilled(): Promise<void>;

  requestPhonePermission(): Promise<void>;

  requestXiaomiPermission(): Promise<void>;

  addListener(eventName: 'answer', listenerFunc: (order: Order) => void): Promise<PluginListenerHandle>;
}

const CallKit = registerPlugin<CallKitPlugin>('CallKit');

export default CallKit;

export declare interface Order {
  id: number;
}