import {
  Component, Input
} from '@angular/core';
import {
  ModalController
} from "@ionic/angular";
import {
  InputSumModalComponent
} from "./input-sum-modal/input-sum-modal.component";
import {
  AbstractControl
} from "@angular/forms";
import {min} from "rxjs";

@Component({
  selector: 'app-input-sum',
  templateUrl: './input-sum.component.html',
  styleUrls: ['./input-sum.component.scss']
})
export class InputSumComponent {
  public isInputSumShowed = false;

  @Input()
  public min = 1;

  @Input()
  public max = 99999;

  constructor(private _modalController: ModalController) {
  }

  async openInputSumForControl(control: AbstractControl): Promise<void> {
    if (this.isInputSumShowed) {
      return;
    }

    this.isInputSumShowed = true;
    const modal = await this._modalController.create({
      component: InputSumModalComponent,
      componentProps: {
        min: this.min,
        max: this.max
      },
      cssClass: "input-sum",
      backdropDismiss: true,
      keyboardClose: false,
    })

    await modal.present();

    await modal.onDidDismiss().then(value => {
      this.isInputSumShowed = false;

      if (value.data) {
        control.setValue(value.data);
      }
    })
  }
}
