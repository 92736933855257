import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';
import { CustomErrorHandler } from './custom-error-handler.service';


@Injectable()
export class CombinedErrorHandlerService implements ErrorHandler {
  private sentryErrorHandler: SentryErrorHandler;
  private customErrorHandler: CustomErrorHandler;

  constructor(
    private injector: Injector,
  ) {
    this.customErrorHandler = this.injector.get(CustomErrorHandler);

    const config: ErrorHandlerOptions = {
      logErrors: true,
      showDialog: false,
    };
    this.sentryErrorHandler = Sentry.createErrorHandler(config);
  }

  async handleError(error: any): Promise<void> {
    await this.customErrorHandler.handleError(error);
    this.sentryErrorHandler.handleError('Sentry: ' + error);
  }
}
