import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RecoveryComponent } from './recovery/recovery.component';
import { ResetComponent } from './reset/reset.component';
import { TokenStoreService } from './token-store.service';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { DisputeGuard } from './dispute.guard';
import { LoginController } from '../login/login.controller';
import { TranslateModule } from '@ngx-translate/core';
import { MobileAppGuard } from './mobile-app.guard';


@NgModule({
  declarations: [
    RecoveryComponent,
    ResetComponent,
  ],
  providers: [
    DatePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    HttpClientModule,
    TranslateModule,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        TokenStoreService,
        AuthGuard,
        DisputeGuard,
        MobileAppGuard,
        LoginController
      ]
    };
  }
}
