import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'replyStatus'
})
export class ReplyStatusPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) {
  }


  transform(value: number, ...args: any[]): any {
    switch (value) {
      case 1:
        return this.translate.instant('notifications.orderStatus.1');
      case 2:
        return this.translate.instant('notifications.orderStatus.2');
      case 3:
        return this.translate.instant('notifications.orderStatus.3');
      case 4:
        return this.translate.instant('notifications.orderStatus.4');
      case 5:
        return this.translate.instant('notifications.orderStatus.5');
      case 6:
        return this.translate.instant('notifications.orderStatus.6');
      case 7:
        return this.translate.instant('notifications.orderStatus.7');
      case 8:
        return this.translate.instant('notifications.orderStatus.8');
      case 9:
        return this.translate.instant('notifications.orderStatus.9');
      case 10:
        return this.translate.instant('notifications.orderStatus.10');
      case 11:
        return this.translate.instant('notifications.orderStatus.11');
      case 12:
        return this.translate.instant('notifications.orderStatus.12');
      case 13:
        return this.translate.instant('notifications.orderStatus.13');
      case 14:
        return this.translate.instant('notifications.orderStatus.14');
      case 15:
        return this.translate.instant('notifications.orderStatus.15');
      default:
        return this.translate.instant('notifications.orderStatus.16');
    }
  }
}
