import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, ParsedUser } from './auth.service';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private tokenStore: TokenStoreService,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.tokenStore.getToken().then(async (token: string): Promise<boolean> => {
      if (token) {
        if (route.routeConfig.path.indexOf('registration') > -1) {
          return true;
        }
        const parsedUser: ParsedUser = this.authService.parsedUser;
        if (parsedUser?.name && parsedUser?.surname) {
          return true;
        } else {
          return this.router.navigateByUrl('/registration/name', {replaceUrl: true})
            .then((): boolean => true);
        }
      } else {
        return this.router.navigateByUrl('/login?backUrl=' + window.location.pathname)
          .then((): boolean => false);
      }
    });
  }
}
