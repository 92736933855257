import {Component, OnInit} from '@angular/core';
import {
  TaskResultController,
  TaskResultVm
} from "../task-result.controller";
import {
  ITaskFileRequest
} from "../../../api-clients/pyjam/extended-client";
import {
  NavigationService
} from "../../../services/navigation.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  ActivatedRoute
} from "@angular/router";
import {LoadingService} from "../../../services/loading.service";
import {FileParameter, TaskResultDataResponse} from "../../../api-clients/pyjam/client";
import {AddingFile, ImageAddingComponent} from "../../../ui-components/image-adding/image-adding.component";
import {DomSanitizer} from "@angular/platform-browser";
import { FileUploadingService } from 'src/app/services/file-uploading.service';

@Component({
  selector: 'edit-task-result',
  templateUrl: './edit-task-result.component.html',
  styleUrls: ['./edit-task-result.component.scss'],
})
export class EditTaskResultComponent implements OnInit {
  private replyId: number;
  public form: FormGroup;
  public files = [] as AddingFile[];

  constructor(
    private activatedRoute: ActivatedRoute,
    public controller: TaskResultController,
    private navigationService: NavigationService,
    private loadingService: LoadingService,
    private fileUploadingService: FileUploadingService,
    public sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.replyId = this.activatedRoute.snapshot.params?.id;
    this.controller.initialisationPromise.then(() => {
      this.form = new FormGroup({
        description: new FormControl('', [
          Validators.required
        ]),
      });
    });
  }

  removeData() {
  }

  async close() {
    await this.navigationService.goBack();
  }

  async onFilesUpdated($event: AddingFile[]) {
    this.files = $event;
  }

  async onOKClick() {
    if (!this.form.valid) {
      return;
    }
    await this.loadingService.start();
    // this.controller.pushFiles(this.files);
    this.controller.pushDescription(this.form.controls.description.value);
    await this.controller.editTaskResult()
      .then(async (res: TaskResultDataResponse) => {
        this.files.forEach(async (file) => {
          await this.fileUploadingService.taskResultAddFileToS3(res.data.id, file);
        });
        await this.navigationService.goBack();
      })
      .catch((err) => {
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }
}
