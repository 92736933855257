import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginController } from '../login.controller';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { IonInput } from '@ionic/angular';
import { LoadingService } from '../../services/loading.service';
import { CountdownEvent } from 'ngx-countdown';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sign-in-confirm',
  templateUrl: './sign-in-confirm.component.html',
  styleUrls: ['./sign-in-confirm.component.scss'],
})
export class SignInConfirmComponent implements OnInit { // FIXME: duplicate in PhoneChangeConfirmComponent
  @ViewChild('codeInput') codeInput: IonInput;
  public loginForm: FormGroup;
  public isCountdownOver: boolean;
  public codeLength: number = 6;
  public isPhone: boolean;
  public timeResendSmsCode: number = 60;
  public showError: boolean = false;

  constructor(
    private loginController: LoginController,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.isPhone = this.loginController.isPhone;
    this.loginForm = this.fb.group({
      login: [this.loginController.login],
      code: ['••••••', [Validators.required, Validators.minLength(this.codeLength)]]
    });
    this.isCountdownOver = false;
  }

  async ionViewWillEnter(): Promise<void> {
    await this.setFocusOnCodeInput();
  }

  public countdownEnded($event: CountdownEvent): void {
    if ($event.action == 'done') {
      this.isCountdownOver = true;
    }
  }

  public async resendSmsCode(): Promise<void> {
    try {
      await this.loadingService.start();

      await this.loginController.sendSmsCodeRequest();
      this.isCountdownOver = false;
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  public async smsCodeInput($event: any): Promise<void> {
    $event.preventDefault();
    const codeValue = this.loginForm.controls['code'].value;
    const lastInputtedNumber: number = this.getPositionOfLastNumericSymbolInCodeForm();
    if ($event.detail.data == null) {
      if ($event.detail.inputType == 'deleteContentBackward') {
        if (lastInputtedNumber >= 0) {
          this.setCodeValue(this.symbolReplacement(codeValue, lastInputtedNumber, '•'));
          this.codeInput.maxlength = this.codeLength + 1;
        }
      } else {
        this.setCodeValue(codeValue);
      }
    } else {
      if (!isNaN(parseInt($event.detail.data))) {
        this.setCodeValue(this.symbolReplacement(codeValue, lastInputtedNumber + 1, $event.detail.data));
      } else {
        this.setCodeValue(codeValue);
      }
    }
    await this.setFocusOnCodeInput();
  }

  public async smsCodeChanged($event: any): Promise<void> {
    const codeValue = this.loginForm.controls['code'].value;

    if (!isNaN(+codeValue)) {
      try {
        this.codeInput.maxlength = this.codeLength;
        this.loginController.pushCode($event.detail.value);

        await this.loginController.sendLoginRequest();

        await this.router.navigate([this.getBackURL()], {replaceUrl: true});
        this.loginController.clear();
      } catch (error) {
        if ({error}?.error?.result?.message === 'auth.invalid_code') {
          console.error(this.translate.instant('auth.invalid_code'));
          // await this.toastService.error(this.translate.instant('auth.invalid_code'));
          this.showError = true;
        } else {
          console.error({error}?.error?.result?.message);
        }
        // this.loginController.clear();
      }
    } else {
      this.showError = false;
    }
  }

  public async onSmsInputClick(): Promise<void> {
    await this.setFocusOnCodeInput();
  }

  public onInputKeyDown($event: KeyboardEvent): void {
    if ($event.key == 'ArrowRight'
      || $event.key == 'ArrowDown'
      || $event.key == 'ArrowLeft'
      || $event.key == 'ArrowUp'
      || $event.key == ' ') {
      $event.preventDefault();
    }
  }

  private async setFocusOnCodeInput(): Promise<void> {
    try {
      const inputElement: HTMLInputElement = await this.codeInput.getInputElement();
      inputElement.focus();
      const cursorPosition: number = this.getPositionOfLastNumericSymbolInCodeForm() + 1;
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    } catch (error) {
      console.error(error);
    }
  }

  private setCodeValue(value: string): void {
    //this.loginForm.controls['code'].setValue(value);
    const newValue: string = value.substring(0, this.codeLength);
    this.loginForm.controls['code'].setValue(newValue);
  }

  private getPositionOfLastNumericSymbolInCodeForm(): number {
    let position: number = -1;
    const codeValue = this.loginForm.controls['code'].value;
    [...codeValue].forEach((char, i): void => {
      if (!isNaN(+char)) {
        position = i;
      }
    });
    return position;
  }

  private symbolReplacement(str: string, index: number, charToReplace: string): string {
    return str.substring(0, index) + charToReplace + str.substring(index + 1);
  }

  private getBackURL(): string {
    let backUrl: string = this.loginController.backUrl
      ? this.loginController.backUrl
      : 'avatar';

    backUrl = !this.authService?.parsedUser || this.authService.parsedUser?.isUserNeedRegistration
      ? 'registration'
      : backUrl;

    return backUrl;
  }
}
