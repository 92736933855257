import {
  Component
} from '@angular/core';
import {
  ModalController
} from "@ionic/angular";
import {
  DateTimeModalComponent
} from "./date-time-modal/date-time-modal.component";
import {
  AbstractControl
} from "@angular/forms";
import {min} from "rxjs";

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent {
  public isDatePickerShowed = false;

  constructor(private _modalController: ModalController) {
  }

  async openDatePickerForControl(control: AbstractControl, showTime: boolean = true, minDate?: string, maxDate?: string): Promise<void> {
    if (this.isDatePickerShowed) {
      return;
    }

    this.isDatePickerShowed = true;
    const modal = await this._modalController.create({
      component: DateTimeModalComponent,
      componentProps: {
        value: control.value,
        minDate: minDate,
        maxDate: maxDate,
        showTime: showTime,
      },
      cssClass: "date-picker",
      backdropDismiss: true,
      keyboardClose: false,
    })

    await modal.present();

    await modal.onDidDismiss().then(value => {
      this.isDatePickerShowed = false;

      if (value.data) {
        control.setValue(new Date(value.data).toISOString());
      }
    })
  }
}
