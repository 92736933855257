import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreventContextMenuDirective } from './prevent-context-menu.directive';
import { LoaderInButtonDirective } from './loader-in-button.directive';

@NgModule({
  declarations: [
    PreventContextMenuDirective,
    LoaderInButtonDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PreventContextMenuDirective,
    LoaderInButtonDirective,
  ]
})
export class DirectivesModule {
}
