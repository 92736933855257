import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'stripe-refresh-url',
  templateUrl: './stripe-refresh-url.component.html',
  styleUrls: ['./stripe-refresh-url.component.scss'],
})
export class StripeRefreshUrlComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
