import { Subscription } from 'rxjs';

export class SubscriptionsBag {
  private _subs: Subscription[] = [];

  public set sub(sub: Subscription) {
    this._subs.push(sub);
  }

  public unsubscribeAll(): void {
    for (let sub of this._subs) {
      sub.unsubscribe();
      sub = null;
    }

    this._subs = [];
  }
}
