import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRoute, } from '@angular/router';
import { SelectedTab } from '../task/task.controller';
import { Subject } from 'rxjs';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  checkNewTips: Subject<boolean> = new Subject();

  public constructor(
    private navCtrl: NavController,
  ) {
  }

  public async goBack(replaceUrl: boolean = false, defaultHref: string = null, relativeTo: ActivatedRoute = null, queryParams = null): Promise<void> {
    const canGoBack = window.history.length > 2 && !defaultHref;
    if (canGoBack) {
      this.navCtrl.back();
    } else {
      defaultHref = defaultHref || window.sessionStorage.getItem('lastURL') || '/';

      const options: NavigationOptions = {
        animated: true,
        replaceUrl,
        relativeTo,
        queryParams,
      };

      this.navCtrl.navigateBack([defaultHref], options).then();
    }
  }

  public goBackWithoutAnimation(): void {
    this.navCtrl.back({animated: false});
  }

  public getUrl(taskVm, selectedTab, activatedRoute): string {
    if (selectedTab === SelectedTab.People) {
      return activatedRoute.snapshot.routeConfig.path.split('/').length === 1 ? '../people' : '../../people';
    }
    if (selectedTab === SelectedTab.Order) {
      if (!taskVm.category_id) {
        return '../category';
      } else if (!taskVm.name) {
        return '../name';
      } else if (!!taskVm.nextQuestionId) {
        return '../question/' + taskVm.nextQuestionId;
      } else if (!taskVm.deadline && (!taskVm.deadline_at || taskVm.deadline_at == '')) {
        return '../deadline';
      } else if (!taskVm.price) {
        return '../price';
      } else if (!taskVm.description || taskVm.description == '') {
        return '../description';
      }
    }
  }
}
