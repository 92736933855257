import {
  Component,
  forwardRef,
  Input,
  OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from "@angular/forms";

@Component({
  selector: 'stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StarsRatingComponent),
    multi: true
  }]
})
export class StarsRatingComponent implements ControlValueAccessor {
  @Input()
  set value(val) {
    this._value = val || null;
    this.onChange(this._value);
  }

  @Input()
  public width: number;

  @Input()
  public activeColor: string = "#FFD700";

  @Input()
  public fontSize: number = 32;

  get value() {
    return this._value;
  }

  private _value;

  onChange(_: any) {
  }

  writeValue(value: number) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() {
  }

}
