import { Component, OnInit } from '@angular/core';
import {
  TaskResultController
} from "./task-result.controller";

@Component({
  selector: 'task-result',
  templateUrl: './task-result.component.html',
  styleUrls: ['./task-result.component.scss'],
  providers: [TaskResultController]

})
export class TaskResultComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
