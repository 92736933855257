import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoaderInButton]',
})
export class LoaderInButtonDirective {
  @Input('appLoaderInButton') srcClick: Function;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
  ) {
  }

  @HostListener('click', ['$event'])
  async onClick(): Promise<void> {
    try {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
      await this.srcClick();
    } finally {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    }
  }
}
