import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss'],
})
export class StarsComponent implements OnInit {

  @Input()
  public stars: number = null

  @Input()
  public width: number = 100;

  constructor() { }

  ngOnInit() {}

}
