import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priceLocale'
})
export class PriceLocalePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    return value.toFixed(2).toLocaleString();
  }
}
