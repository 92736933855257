import { Component, OnInit } from '@angular/core';
import {ModalComponentBase} from "../../services/modal-window";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'dispute-timeout-popup',
  templateUrl: './dispute-timeout-popup.component.html',
  styleUrls: ['./dispute-timeout-popup.component.scss'],
})
export class DisputeTimeoutPopupComponent extends ModalComponentBase implements OnInit {

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  ngOnInit() {}

  onOkClick() {
    super.dismiss().then();
  }
}
