import { PluginListenerHandle, registerPlugin } from '@capacitor/core';

export interface VoipPlugin {
  register(): Promise<void>;

  addListener(eventName: 'voipPushRegistry', listenerFunc: (token: Token) => void): Promise<PluginListenerHandle>;

  addListener(eventName: 'handleOrder', listenerFunc: (order: Order) => void): Promise<PluginListenerHandle>;
}

const Voip = registerPlugin<VoipPlugin>('Voip');

export default Voip;

export declare interface Token {
  token: string;
}

export declare interface Order {
  id: number;
}
