import { Component, OnInit } from '@angular/core';
import { LoginController } from '../login.controller';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PhoneInputControlValue } from '../../ui-components/phone-input/phone-input.component';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { PlatformService } from '../../services/platform.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public loginForm: FormGroup;
  public agreementChecked: boolean = false;
  public phoneMode: boolean = true;

  constructor(
    public platformService: PlatformService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private loginController: LoginController,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loginForm = this.fb.group({
      phoneInput: new FormControl(new PhoneInputControlValue()),
    });
  }

  public async appleSignIn(): Promise<void> {
    try {
      const appleUser: SignInWithAppleResponse = await SignInWithApple.authorize(environment.apple);
      this.authService.appleUser = appleUser;
      console.log('appleUser:', appleUser);

      await this.loginController.loginWithToken('apple', this.authService.appleUser.response.identityToken);

      await this.router.navigate([this.getBackURL()], {replaceUrl: true});
      this.loginController.clear();
    } catch (error) {
      console.error(error);
    }
  }

  public async googleSignIn(): Promise<void> {
    try {
      await GoogleAuth.initialize(environment.google);

      const googleUser: User = await GoogleAuth.signIn();
      this.authService.googleUser = googleUser;
      console.log('googleUser:', googleUser);

      await this.loginController.loginWithToken('google', this.authService.googleUser.authentication.idToken);

      await this.router.navigate([this.getBackURL()], {replaceUrl: true});
      this.loginController.clear();
    } catch (error) {
      console.error(error);
    }
  }

  public async toggleMode(): Promise<void> {
    this.agreementChecked = false;
    this.phoneMode = !this.phoneMode;

    if (this.phoneMode) {
      this.loginForm = this.fb.group({
        phoneInput: new FormControl(new PhoneInputControlValue()),
      });
    } else {
      this.loginForm = this.fb.group({
        email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/\.[a-z]{2,63}$/)]),
      });
    }
  }

  public async phoneSignIn(): Promise<void> {
    try {
      await this.loadingService.start();

      this.loginController.changeIsPhone(true);
      this.loginController.pushLogin(this.loginForm.value.phoneInput.toString());
      await this.loginController.sendSmsCodeRequest();
      await this.router.navigate(['login/sign-in-confirm'], {skipLocationChange: true});
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  public async emailSignIn(): Promise<void> {
    try {
      await this.loadingService.start();

      this.loginController.changeIsPhone(false);
      this.loginController.pushLogin(this.loginForm.value.email);
      await this.loginController.sendSmsCodeRequest();
      await this.router.navigate(['login/sign-in-confirm'], {skipLocationChange: true});
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  private getBackURL(): string {
    let backUrl: string = this.loginController.backUrl
      ? this.loginController.backUrl
      : 'avatar';

    backUrl = !this.authService?.parsedUser || this.authService.parsedUser?.isUserNeedRegistration
      ? 'registration'
      : backUrl;

    return backUrl;
  }
}
