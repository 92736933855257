import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { RecoveryData } from '../../interfaces';
// import { LoadingService } from '../../services/loading.service';
// import { ToastService } from '../../services/toast.service';
// import { AuthService } from '../auth.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss'],
})
export class RecoveryComponent implements OnInit {

  recoveryForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    // private router: Router,
    // private authService: AuthService,
    // private toastService: ToastService,
    // private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      phone: ['', [Validators.required, Validators.pattern('[+0-9]{12}')]],
    });
  }

  recovery() {
    console.warn('Not implemented');
    // if (this.recoveryForm.invalid) {
    //   return;
    // }
    //
    // this.loadingService.start().then();
    //
    // const recoveryData: RecoveryData = {
    //   phone: this.recoveryForm.value.phone
    // };

    // this.authService.recovery(recoveryData)
    //   .subscribe((success) => {
    //     this.toastService.success(success.message);
    //     this.router.navigate(['reset'], {state: {phone: this.recoveryForm.value.phone}});
    //   }, async (errorText) => {
    //     await this.toastService.error(errorText);
    //   }).add(() => {
    //     this.loadingService.stop().then();
    //   });
  }
}
