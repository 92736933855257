import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss'],
})
export class CookiesPolicyComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
