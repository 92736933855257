import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileParameter } from '../../api-clients/pyjam/client';
import { ActivatedRoute } from '@angular/router';
import { ITaskFileRequest } from '../../api-clients/pyjam/extended-client';
import { LoadingService } from '../../services/loading.service';
import { AddingFile } from '../../ui-components/image-adding/image-adding.component';

@Component({
  selector: 'extra-work-description',
  templateUrl: './extra-work-description.component.html',
  styleUrls: ['./extra-work-description.component.scss'],
})
export class ExtraWorkDescriptionComponent implements OnInit {
  private replyId: number;
  public form: FormGroup;
  public files = [] as ITaskFileRequest [];
  public pushedFiles: ITaskFileRequest [] = [];
  public filesForSent: FileParameter [] = [];
  public isFreeExtraWork = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
  ) {
  }

  ngOnInit() {
    this.replyId = this.activatedRoute.snapshot.params?.id;
    this.form = new FormGroup({
      description: new FormControl('', [
        Validators.required
      ]),
      /*price: new FormControl({
        value: null,
        disabled: this.isFreeExtraWork
      }, [
        Validators.min(1),
        Validators.max(999999)
      ]),
      checkValuePrice: new FormControl(!this.isFreeExtraWork, [])*/
    });
  }

  public async onReplyExtraWorkClick() {
    this.loadingService.start()
      .then(() => {
        // this.client.extraWorkPost(
        //   {
        //     reply_id: this.replyId,
        //     description: this.form.controls.description.value,
        //   } as ExtraWorkRequest
        // ).toPromise()
        //   .then(async () => {
        //     await this.router.navigate(['/reply/' + this.replyId], {replaceUrl: true});
        //   })
        //   .catch(async ex => {
        //   });
      })
      .finally(async () => {
        await this.loadingService.stop();
      });
  }

  public async onFilesUploaded(files: AddingFile[]): Promise<void> {
    // let filesForSent: FileParameter[] = [];
    // for (let file of files) {
    //   let fileForPush: FileParameter = {
    //     fileName: file.fileName,
    //     data: file.file
    //   };
    //   filesForSent.push(fileForPush);
    // }
    // this.filesForSent = filesForSent;
    // this.files = files;
  }

  public async onDeleteFile(id: number): Promise<void> {
  }

  /*onInputKeyDown($event: KeyboardEvent) {
    if ($event.key == "ArrowRight" || $event.key == "ArrowDown"
      || $event.key == "ArrowLeft" || $event.key == "ArrowUp"
      || $event.key == "Delete" || $event.key == "Backspace"
      || $event.key == "0" || $event.key == "1"
      || $event.key == "2" || $event.key == "3"
      || $event.key == "4" || $event.key == "5"
      || $event.key == "6" || $event.key == "7"
      || $event.key == "8" || $event.key == "9") {
      if (($event.key == "0" || $event.key == "1"
          || $event.key == "2" || $event.key == "3"
          || $event.key == "4" || $event.key == "5"
          || $event.key == "6" || $event.key == "7"
          || $event.key == "8" || $event.key == "9")
        && this.form.controls?.price?.value?.toString()?.length >= 6) {
        $event.preventDefault();
      }
    } else {
      $event.preventDefault();
    }
  }

  checkValuePriceChanged($event: any) {
    if ($event.detail.checked) {
      this.form.controls.price.enable();
    } else {
      this.form.controls.price.disable();
      this.form.controls.price.setValue(null);
    }
  }*/
}
