import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pyjam-animate',
  templateUrl: './pyjam-animate.component.html',
  styleUrls: ['./pyjam-animate.component.scss'],
})
export class PyjamAnimateComponent {
  delayedRedirect: any;

  constructor(private router: Router) {
  }

  ionViewWillEnter() {
    this.delayedRedirect = setTimeout(async (): Promise<void> => {
      if (!localStorage.getItem('introPassed')) {
        await this.router.navigate(['/intro'], {replaceUrl: true});
      } else {
        if (this.router.url === '/pyjam-animate') {
          await this.router.navigate(['/avatar']);
        }
      }
    }, 2_000); // animation 2s
  }
}
