import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignInConfirmComponent } from './sign-in-confirm/sign-in-confirm.component';
import { IonicModule } from '@ionic/angular';
import { LoginController } from './login.controller';
import { ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { DirectivesModule } from '../directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { AgreementsCheckboxComponent } from './agreements-checkbox/agreements-checkbox.component';
// import { WelcomeComponent } from './welcome/welcome.component';


@NgModule({
  declarations: [
    SignInComponent,
    SignInConfirmComponent,
    AgreementsCheckboxComponent,
    // WelcomeComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    LoginRoutingModule,
    IonicModule,
    ReactiveFormsModule,
    CountdownModule,
    DirectivesModule,
    TranslateModule,
    UiComponentsModule,
  ],
  providers: [
    LoginController
  ]
})
export class LoginModule {
}
