import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode, } from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { TokenStoreService } from './token-store.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenStore: TokenStoreService,
    private authService: AuthService,
    private translate: TranslateService,
    private toastService: ToastService,
  ) {
  }

  private async patchReqByTokenIfPossible(request: HttpRequest<any>): Promise<HttpRequest<any>> {
    const token: string = await this.tokenStore.getToken();
    return token
      ? request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`)})
      : request;
  }

  private async signOutAndGoToSignIn(): Promise<void> {
    await this.authService.clearTokens();
    await this.router.navigateByUrl('/login/sign-in');
  }

  private async interceptInternal(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    if (!request.url.includes(environment.urlBackEndApi) || request.url.includes('/auth/refresh')) {
      return lastValueFrom(next.handle(request));
    }

    const now: Date = new Date();
    const tokenExpired: boolean = this.authService.parsedToken?.expirationDate.valueOf() <= now.valueOf();

    // Предварительный рефреш
    if (tokenExpired) {
      try {
        console.warn('Token expired!');
        await this.authService.refreshToken();
      } catch (error) {
        // Тут может быть ошибка, если мы здесь начнём разлогенивать юзера, то под это может попасть случай, когда нет интернета.
        // Поэтому мы просто проглатываем этот ексепшон, всё равно по 401 он рефрешнется, если что свалится и после запроса.
      }
    }

    // Запрос
    try {
      return await lastValueFrom(next.handle(await this.patchReqByTokenIfPossible(request)));
    } catch (error) {
      if (error?.status !== HttpStatusCode.Unauthorized) {
        await this.handleHttpException(error);
        throw error;
      }
    }

    // Рефреш
    try {
      await this.authService.refreshToken();
    } catch (error) {
      if (error?.status === HttpStatusCode.BadRequest) {
        await this.signOutAndGoToSignIn();
      }
      await this.handleHttpException(error);
      throw error;
    }

    // Повторный запрос
    try {
      return await lastValueFrom(next.handle(await this.patchReqByTokenIfPossible(request)));
    } catch (error) {
      if (error?.status === HttpStatusCode.Unauthorized) {
        await this.signOutAndGoToSignIn();
      }
      await this.handleHttpException(error);
      throw error;
    }
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.interceptInternal(req, next));
  }

  private async handleHttpException(exception: any): Promise<void> {
    const errorText = (exception?.error?.text === 'function') ? await exception?.error?.text() : null;
    let responseErrorMessage: string = '';

    if (errorText) {
      const parseErrorText = JSON.parse(errorText);
      responseErrorMessage = parseErrorText?.message ?? parseErrorText?.error;
    }
    const message = responseErrorMessage ?? exception?.message;

    if (message) {
      const codesForRedirect: string[] = ['ban.task', 'ban.reply', 'ban.forever'];

      if (exception.status == HttpStatusCode.Forbidden && codesForRedirect.indexOf(message) != -1) {
        await this.toastService.error(this.translate.instant('errors.' + message));
        await this.router.navigate(['/avatar']);
      }
    }
  }
}
