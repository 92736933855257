import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileParameterExtended} from "../../api-clients/pyjam/extended-client";
import {ModalController} from "@ionic/angular";
import {DomSanitizer} from "@angular/platform-browser";
import Swiper from "swiper";
import {ProfileFileRequest} from "../../api-clients/pyjam/client";
import {AddingFile} from "../image-adding/image-adding.component";

@Component({
  selector: 'app-add-description-to-image',
  templateUrl: './add-description-to-image.component.html',
  styleUrls: ['./add-description-to-image.component.scss'],
})
export class AddDescriptionToImageComponent implements OnInit {
  @Input()
  public files: AddingFileWithDescription[] = [];
  public imagePreviews = {};

  constructor(private modalController: ModalController,
              private sanitizer: DomSanitizer,) {
  }

  ngOnInit() {
    if (this.files.length > 0) {
      setTimeout(() => {
        const swiper = new Swiper('.customSwiper', {
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          },
        });
      });
    }
  }

  public async saveDescription(description, file: AddingFileWithDescription): Promise<void> {
    file.description = description.value;
  }

  public async finishEditFilesInfo(): Promise<void> {
    await this.modalController.dismiss({files: this.files});
  }

  public sanitize(data: string) {
    return this.sanitizer.bypassSecurityTrustUrl(data);
  }

  public async close() {
    await this.modalController.dismiss({});
  }
}

export class AddingFileWithDescription extends AddingFile {
  description: string;
}
